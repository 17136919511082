// Body
$body-bg: #fafafa;
// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;
// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif : 'proxima-nova',
'Source Sans Pro',
'Helvetica Neue',
Helvetica,
Arial,
sans-serif;

$font-size-base: 16px;
$line-height-base: 1.1;
$text-color: #636b6f;
// Navbar
$navbar-default-bg: #fff;
// Buttons
$btn-default-color: $text-color;
// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);
// Panels
$panel-default-heading-bg: #fff;

$black: #000000;
$white: #ffffff;
$border-color: #cccccc;
$focus-color: #f0258a;

/* ICONS - BEING USED */
$icon-font: 'Glyphicons Halflings';
$ic-checkmark: "\e013";
$ic-circle: "\e165";
$ic-minus: "\2212";