@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin xtransition($value...) {
  -moz-transition: $value;
  -ms-transition: $value;
  -o-transition: $value;
  -webkit-transition: $value;
  transition: $value;
}

@mixin xtransition-delay($value...){
  -webkit-transition-delay: $value;
  -moz-transition-delay: $value;
  -o-transition-delay: $value;
  transition-delay: $value;
}

@mixin transform($value) {
  -webkit-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  transform: $value;
}

@mixin transform-origin($value) {
  -webkit-transform-origin: value;
  transform-origin: value;
}

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframe($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}
    
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin opacity($opacity){
  filter: alpha(opacity=($opacity*100));
  opacity: $opacity;
}

@mixin blur($radius,$opacity){
  -webkit-filter: blur($radius);
  -moz-filter: blur($radius);
  -o-filter: blur($radius);
  -ms-filter: blur($radius);
  filter: blur($radius);
  @include opacity($opacity);
}

@mixin xplaceholder {
  &::-webkit-input-placeholder {
    @content;
  }   
  &:-moz-placeholder {
    @content;
  }      
  &::-moz-placeholder { 
    @content;
  } 
  &:-ms-input-placeholder {  
    @content;
  }   
}

@mixin rotation($value){
  -webkit-transform: rotate($value); 
  -moz-transform: rotate($value);	 
}

@mixin box-sizing($value) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  box-sizing: $value;
}

@mixin max-screen($width) {
  @media screen and (max-width: #{$width}) {
    @content;
  }
}