
$font-path: '/fonts';
@font-face {
  font-family: 'proxima-nova';
  src: url('#{$font-path}/proxima/proximanova-regular-webfont.eot');
  src: url('#{$font-path}/proxima/proximanova-regular-webfont.eot?iefix') format('embedded-opentype'),
  url('#{$font-path}/proxima/proximanova-regular-webfont.woff') format('woff'),
  url('#{$font-path}/proxima/proximanova-regular-webfont.ttf') format('truetype'),
  url('#{$font-path}/proxima/proximanova-regular-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'proxima-nova';
  src: url('#{$font-path}/proxima/proximanova-light-webfont.eot');
  src: url('#{$font-path}/proxima/proximanova-light-webfont.eot?iefix') format('embedded-opentype'),
  url('#{$font-path}/proxima/proximanova-light-webfont.woff') format('woff'),
  url('#{$font-path}/proxima/proximanova-light-webfont.ttf') format('truetype'),
  url('#{$font-path}/proxima/proximanova-light-webfont.svg') format('svg');
  font-weight: 300;
  font-style: 300;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('#{$font-path}/proxima/proximanova-bold-webfont.eot');
  src: url('#{$font-path}/proxima/proximanova-bold-webfont.eot?iefix') format('embedded-opentype'),
  url('#{$font-path}/proxima/proximanova-bold-webfont.woff') format('woff'),
  url('#{$font-path}/proxima/proximanova-bold-webfont.ttf') format('truetype'),
  url('#{$font-path}/proxima/proximanova-bold-webfont.svg') format('svg');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('#{$font-path}/proxima/proximanova-semi-bold-webfont.eot');
  src: url('#{$font-path}/proxima/proximanova-semi-bold-webfont.eot?iefix') format('embedded-opentype'),
  url('#{$font-path}/proxima/proximanova-semi-bold-webfont.woff') format('woff'),
  url('#{$font-path}/proxima/proximanova-semi-bold-webfont.ttf') format('truetype'),
  url('#{$font-path}/proxima/proximanova-semi-bold-webfont.svg') format('svg');
  font-weight: 600;
}


// Variables
@import "mixin";
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "form";
@import "global";
@import "theme";
