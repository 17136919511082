body {
  background-color: #EDEFF3;
  &._ssl {
    font-family: 'proxima-nova', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.1;
    color: #444444;
    * {
      box-sizing: border-box;
    }
    a {
      text-decoration: none;
      outline: none;
    }
    ._link {
      color: #f0258a;
      display: inline-block;
      &:hover,
      &:focus {
        color: #f33490;
      }
    }
    ._action,
    .btn {
      padding: 10px;
      line-height: 1.5;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      outline: none;
      border: none;
      appearance: none;
      @include border-radius(3px);
      min-width: 200px;
      display: inline-block;
      box-shadow: none;
      // box-shadow: 0 4px 6px rgba(50, 50, 93, .3), 0 1px 3px rgba(0, 0, 0, .2);
      color: #ffffff;
      background-color: #f0258a;
      &._small {
        width: auto;
        min-width: 100px;
        font-size: 16px;
        padding: 5px 5px;
        line-height: 1.2;
      }
      &._input {
        margin-top: 19px;
        background-color: #444444;
        padding: 7px;
      }
      &.disabled {
        color: #B50057;
        background-color: rgb(253, 86, 167);
        pointer-events: none;
      }
      &._loading {
        pointer-events: none;
      }
      &:active,
      &:hover {
        color: #ffffff;
        background-color: rgb(248, 106, 177);
        box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
      }
    }
    .btn {
      padding: 5px 10px;
      margin-bottom: 5px;
      line-height: 1.5;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      outline: none;
      border: none;
      appearance: none;
      @include border-radius(3px);
      min-width: 50px;
      display: inline-block;
      vertical-align: middle;
      color: #ffffff;
      background-color: #444444;
      &.btn-danger {
        background-color: $brand-danger;
      }
      &:active,
      &:hover {
        color: #ffffff;
        background-color: rgb(248, 106, 177);
        box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
      }
    }
    .dataTables_wrapper .dataTables_length,
    .dataTables_wrapper .dataTables_filter {
      margin-bottom: 15px;
    }
    .dataTables_wrapper .dataTables_info {
      display: inline-block;
      vertical-align: middle;
      float: none;
      ~.actions {
        display: inline-block;
        vertical-align: middle;
        float: none;
      }
    }
    &._auth-page {
      font-size: 15px;
      input,
      select,
      textarea {
        padding: 15px;
      }
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
          line-height: 1.2;
        }
      }
      ._page-title {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      ._medium {
        padding: 30px;
        background-color: #ffffff;
        box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
        @include border-radius(5px);
      }
      ._form-wrap {
        margin: 0 auto;
        max-width: 400px;
      }
      ._logo {
        margin-bottom: 30px;
        text-align: center;
        img {
          width: 200px;
          max-width: 100%;
        }
      }
      ._aside {
        line-height: 36px;
        vertical-align: middle;
        ._link {
          line-height: 18px;
        }
      }
    }
  }
}
