body {
  &._ssl {
    &._app-page {
      .main-header {
        .navbar {
          display: none;
        }
        .logo-lg {
          display: inline-block;
        }
        @media (max-width: 767px) {
          .navbar {
            margin: 0;
            position: absolute;
            right: 30px;
            top: 0;
            width: auto;
            display: block;
            .sidebar-toggle {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
        .sidebar-toggle {
          font-size: 25px;
          color: #444444;
          &:hover {
            color: #f0258a;
          }
          @media screen and (min-width: 768px) {
            display: none;
          }
        }
      }
      .content-wrapper {
        margin-right: 30px;
        >.content {
          padding: 30px;
          background-color: #ffffff;
          box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
          @include border-radius(5px);
        }
      }
      .main-header .logo {
        text-align: left;
        padding-left: 30px;
        float: none;
        display: inline-block;
        img {
          height: 25px;
          width: auto;
          max-width: 100%;
        }
      }
      .main-header {
        margin-bottom: 30px;
      }
      .main-sidebar,
      .left-side {
        padding-top: 80px;
      }
      ._page-title,
      .panel-default>.panel-heading {
        text-transform: uppercase;
        color: #f0258a;
        font-size: 30px;
        margin-bottom: 30px;
        margin-top: 0;
      }
      .panel {
        margin-bottom: 0;
        background-color: transparent;
        border: none;
        border-radius: 0;
        box-shadow: none;
      }
      .sidebar-menu {
        >li {
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 0px;
          padding-bottom: 15px;
          .active {
            a {
              color: #f0258a;
            }
          }
          a {
            &:hover,
            &:focus {
              color: #f0258a;
            }
            font-size: 16px;
            color: #444444;
          }
          >a {
            font-weight: 600;
            font-size: 18px;
            color: #444444;
            line-height: 25px;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0px;
            padding-bottom: 0;
            &:hover,
            &:focus {
              color: #f0258a;
            }
            span {
              display: inline-block;
              vertical-align: middle;
            }
            .pull-right-container {
              position: relative;
              right: 0;
              top: 0;
              margin-top: 0;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
        .treeview-menu {
          padding-top: 15px;
          padding-left: 10px;
        }
      }
      .sidebar-menu>li>a>.fa,
      .sidebar-menu>li>a>.glyphicon,
      .sidebar-menu>li>a>.ion {
        font-size: 17px;
        display: inline-block;
        vertical-align: middle;
      }
      .main-sidebar,
      .left-side {
        width: 350px;
      }
      .content-wrapper,
      .right-side,
      .main-footer {
        margin-left: 350px;
        @include max-screen(768px) {
          margin-left: 30px;
        }
      }
      @media screen and (min-width: 768px) {
        &.sidebar-mini.sidebar-collapse .content-wrapper,
        &.sidebar-mini.sidebar-collapse .right-side,
        &.sidebar-mini.sidebar-collapse .main-footer {
          margin-left: 75px !important;
        }
      }
      .table>thead>tr>th,
      .table>thead>tr>td,
      .table>tbody>tr>th,
      .table>tbody>tr>td,
      .table>tfoot>tr>th,
      .table>tfoot>tr>td {
        vertical-align: middle;
        .label {
          display: inline-block;
          margin-bottom: 3px;
        }
      }
      table.dataTable td.select-checkbox {
        font-family: $icon-font;
        text-align: center;
        vertical-align: middle;
        &:before {
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          content: ' ';
          margin-top: 0;
          margin-left: 0;
          cursor: pointer;
          border: 1px solid #cccccc;
          display: inline-block;
          height: 20px;
          width: 20px;
          min-width: 20px;
          margin: -4px 0 0 0;
          outline: 0;
          padding: 0;
          text-align: center;
          vertical-align: middle;
          -webkit-appearance: none;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          float: none;
          @include border-radius(2px);
        }
      }
      table.dataTable tr.selected td.select-checkbox {
        &:after {
          display: none;
        }
        &:before {
          content: $ic-checkmark;
          color: $focus-color;
        }
      }
      table.dataTable.no-footer {
        margin-top: 15px;
      }
      table.dataTable {
        th,
        td {
          vertical-align: middle;
        }
      }
      .dataTables_wrapper .dataTables_info {
        padding-top: 0;
      }
    }
  }
}
