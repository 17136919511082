body {
  &._ssl {
    .select2-container {
      max-width: 100%;
      width: 100%;
    }
    ._input-container {
      overflow: hidden;
      margin-bottom: 20px;
      // box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
      ._input-wrap {
        border-bottom: 1px #ccc solid;
        input:not([type="radio"]),
        input:not([type="checkbox"]),
        select,
        textarea {
          border: none;
        }
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
    label {
      font-weight: 400;
      ._name {
        margin-bottom: 5px;
      }
      vertical-align: top;
      margin-bottom: 0;
      cursor: pointer;
      .checkbox {
        &.inline {
          display: inline-block;
          vertical-align: middle;
          label {
            padding-left: 0;
          }
        }
      }
      &._date-input {
        ._name {
          display: block;
        }
        select {
          display: inline-block;
          margin-right: 0.3rem;
          width: auto;
          min-width: auto;
        }
      }
      &.checkbox,
      &.radio,
      &.checkbox-inline,
      &.radio-inline {
        line-height: 36px;
        padding-left: 0;
        margin-top: 0;
        margin-right: 0px;
        +.radio-inline,
        +.checkbox-inline {
          margin-left: 0;
        }
        ._name {
          line-height: 18px;
        }
      }
      &.checkbox-inline {
        margin-top: 6px;
        line-height: 16px;
      }
      &._full-input {
        width: 100%;
        input,
        textarea,
        select {
          min-width: 100%;
          max-width: 100%;
          width: 100%;          
        }
        ._control {
          display: none;
        }
        ._name {
          display: block;
          margin-bottom: 0.3rem;
        }
      }
    }
    .radio input[type="radio"],
    .radio-inline input[type="radio"],
    .checkbox input[type="checkbox"],
    .checkbox-inline input[type="checkbox"] {
      position: relative;
      background: transparent;
      clear: none;
      cursor: pointer;
      display: inline-block;
      height: 20px;
      width: 20px;
      min-width: 20px;
      margin: -4px 0 0 0;
      outline: 0;
      padding: 0;
      text-align: center;
      vertical-align: middle;
      -webkit-appearance: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      float: none;
      @include border-radius(2px);
      &._action {
        height: 26px;
        width: 26px;
        margin-top: 0;
        &:before {
          line-height: 1;
          font-size: 22px;
          width: 100%;
        }
      }
    }
    .radio input[type="radio"],
    .radio-inline input[type="radio"] {
      @include border-radius(20px);
    }
    input[type=checkbox]:checked:before {
      content: $ic-checkmark;
      color: $focus-color;
    }
    input[type=radio]:checked:before {
      content: $ic-circle;
      color: $focus-color;
    }
    input[type=checkbox]:indeterminate:before {
      content: $ic-minus;
      color: $border-color;
    }
    input[type=checkbox]:before,
    input[type=radio]:checked:before {
      display: inline-block;
      vertical-align: top;
      font-family: $icon-font;
      width: 16px;
      speak: none;
      outline: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 16px;
      font-size: 14px;
    }
    input[type="file"]:focus,
    input[type="radio"]:focus,
    input[type="checkbox"]:focus {
      outline: none;
    }
    input,
    select,
    textarea {
      border: 1px solid $border-color;
      @include border-radius(3px);
      background-color: #ffffff;
      padding: 9px 8px;
      font-size: 18px;
      line-height: 1;
      color: #444444;
      margin: 0;
      outline: none;
      @include xplaceholder {
        color: #ccc;
      }
      &:focus {
        border-color: #f0258a;
        @include box-shadow(none);
      }
    }
    select {
      background-color: #ffffff;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
